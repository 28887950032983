<script setup lang="ts">
import { CACHE_KEY_TAGS } from 'configs';
import { colors } from 'shared-components';
import { getDurationInSeconds } from 'utils';
import { ENDPOINT } from '@/config';
import type { AutoplayCreatorSampleStoryblok } from '@/storyblok-types';
import type { Shop } from '@/types/shops';

const NUMBER_OF_ROWS = 2;

withDefaults(defineProps<{
  blok: AutoplayCreatorSampleStoryblok;
}>(), {  })

const { BASE_URL } = useBaseUrl();

const { data } = await useCachedAsyncData(
  'bestsellingShops',
  () => $fetch<Shop[]>(ENDPOINT.BEST_SELLING_SHOPS, { baseURL: BASE_URL }),
  {
    clientMaxAge: getDurationInSeconds({ minutes: 15 }),
    serverCacheTags: [ CACHE_KEY_TAGS.NUXT_MULTI_CACHE_DEFAULT ],
    serverMaxAge: getDurationInSeconds({ hours: 1 }),
  },
);

const shops = computed(() => data.value ?? []);

const firstHalfOfShops = computed(
  () => shops.value.slice(0, shops.value.length / NUMBER_OF_ROWS),
);
const secondHalfOfShops = computed(
  () => shops.value.slice(shops.value.length / NUMBER_OF_ROWS),
);

const isPurpleSectionBg = computed(() => __props.blok.backgroundColor?.value === colors.purple);

const spacingClasses = computed(() => getSpacingClassesForBlok(__props.blok));
</script>

<template>
  <div
    v-editable="blok"
    class="bg-grey-light"
    :class="[isPurpleSectionBg ? 'text-white' : 'text-dark', spacingClasses]"
    :style="{ backgroundColor: blok.backgroundColor?.value }"
  >
    <div class="group/creator-carousel container relative max-w-7xl overflow-hidden px-0">
      <div
        class="absolute left-0 top-0 z-20 h-full w-8 bg-linear-to-r from-(--gradient-to-color) to-transparent"
        :style="{ '--gradient-to-color': blok.backgroundColor?.value }"
      />
      <div
        class="absolute right-0 top-0 z-20 h-full w-8 bg-linear-to-l from-(--gradient-to-color) to-transparent"
        :style="{ '--gradient-to-color': blok.backgroundColor?.value }"
      />
      <div class="relative z-10">
        <h3
          v-if="blok.headline"
          class="mb-6 mt-0 text-center text-lg font-black md:text-2xl"
        >
          {{ blok.headline }}
        </h3>
        <div
          v-for="(shopList, listIndex) of [firstHalfOfShops, secondHalfOfShops]"
          :key="listIndex"
          class="relative flex"
          :class="{
            'mt-4': listIndex === 1,
          }"
        >
          <div
            v-for="marqueeIteration in NUMBER_OF_ROWS"
            :key="marqueeIteration"
            class="flex whitespace-nowrap group-hover/creator-carousel:[animation-play-state:paused]"
            :class="{
              'animate-marquee': listIndex === 0 && marqueeIteration === 1,
              'absolute top-0 animate-marquee2': listIndex === 0 && marqueeIteration === 2,
              'animate-marquee-reverse': listIndex === 1 && marqueeIteration === 1,
              'absolute top-0 animate-marquee2-reverse': listIndex === 1 && marqueeIteration === 2,
            }"
          >
            <div
              v-for="(shop, shopIndex) of shopList"
              :key="shop.slug ?? shopIndex"
              class="px-2"
            >
              <div class="flex w-[320px] shrink-0 items-center justify-stretch lg:w-[420px]">
                <ShopInformationBasic
                  is-in-product-stage
                  class="w-full"
                  :shop="shop"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
